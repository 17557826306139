var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Receiving bj" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          { staticClass: "d-flex a-center" },
          [
            _c("el-button", {
              staticClass: "bjsearch",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.Refresh },
            }),
            _c(
              "div",
              { staticClass: "ml10", staticStyle: { color: "#c55056" } },
              [
                _vm._v(
                  " 在城市仓后台，选择跟车单打印菜单,选择供货日期(发车日期),点击打印跟车单后,该供货日方可进行提货单打印 "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "seach" },
          [
            _c(
              "div",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.setDefault } },
                  [_vm._v("设置默认分货方式")]
                ),
              ],
              1
            ),
            _vm.role == "tdc"
              ? _c(
                  "div",
                  {
                    staticClass: "inputs",
                    staticStyle: { "margin-right": "5px" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          placeholder: "请选择自提点",
                        },
                        model: {
                          value: _vm.from.store_delivery_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "store_delivery_id", $$v)
                          },
                          expression: "from.store_delivery_id",
                        },
                      },
                      _vm._l(_vm.arrList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "inputs1" },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入批次号", clearable: "" },
                  model: {
                    value: _vm.from.delivery_take_order_batch_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "delivery_take_order_batch_no", $$v)
                    },
                    expression: "from.delivery_take_order_batch_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.search },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
                "tooltip-effect": "dark",
              },
            },
            [
              _vm._v(" > "),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_take_order_batch_no",
                  align: "center",
                  label: "合并批次号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(
                                scope.row.delivery_take_order_batch_no
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_at_start",
                  align: "center",
                  label: "订单时间起",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(
                                _vm.tool.timestampToTime(scope.row.pay_at_start)
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_at_end",
                  align: "center",
                  label: "订单时间止",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(
                                _vm.tool.timestampToTime(scope.row.pay_at_end)
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.role == "tdc"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "reveive_time",
                      align: "center",
                      label: "自提点名称",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.delivery
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.delivery.name)),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1098509004
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "member_number",
                  align: "center",
                  label: "客户数量",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.member_number)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reveive_time",
                  align: "center",
                  label: "订单数量",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.order_number)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "print_status",
                  align: "center",
                  label: "打印状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.print_status == 2
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已打印"),
                            ])
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("未打印"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.printlist(scope.row)
                              },
                            },
                          },
                          [_vm._v("横版打印")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.printlist(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v("竖版打印")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.exportHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 导出 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                        _vm.role == "tdc"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.printGoods(scope.row)
                                  },
                                },
                              },
                              [_vm._v("分货单")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.from.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("print-set", {
        ref: "printSetRef",
        attrs: { dialogVisible: _vm.dialogVisible, showSetButton: true },
        on: {
          close: function ($event) {
            _vm.dialogVisible = false
          },
          submitHandle: _vm.submitHandle,
          setPrint: _vm.setPrint,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
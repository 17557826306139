<template>
  <div class="Receiving bj">
    <div class="title">
      <div class="d-flex a-center">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <div style="color: #c55056" class="ml10">
          在城市仓后台，选择跟车单打印菜单,选择供货日期(发车日期),点击打印跟车单后,该供货日方可进行提货单打印
        </div>
      </div>
      <div class="seach">
        <!-- <div class="times">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div> -->
        <div style="margin-right: 10px">
          <el-button type="primary" @click="setDefault"
            >设置默认分货方式</el-button
          >
        </div>
        <div class="inputs" v-if="role == 'tdc'" style="margin-right: 5px">
          <el-select
            v-model="from.store_delivery_id"
            filterable
            clearable
            placeholder="请选择自提点"
          >
            <el-option
              :key="item.id"
              v-for="item in arrList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs1">
          <el-input
            placeholder="输入批次号"
            v-model="from.delivery_take_order_batch_no"
            clearable
          ></el-input>
        </div>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        >
        <!-- <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column> -->
        <el-table-column
          prop="delivery_take_order_batch_no"
          align="center"
          label="合并批次号"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.delivery_take_order_batch_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at_start" align="center" label="订单时间起">
          <template slot-scope="scope">
            {{ $empty.empty(tool.timestampToTime(scope.row.pay_at_start)) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at_end" align="center" label="订单时间止">
          <template slot-scope="scope">
            {{ $empty.empty(tool.timestampToTime(scope.row.pay_at_end)) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="reveive_time"
          align="center"
          label="自提点名称"
          v-if="role == 'tdc'"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.delivery">{{ scope.row.delivery.name }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="member_number" align="center" label="客户数量">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.member_number) }}
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="订单数量">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_number) }}
          </template>
        </el-table-column>
        <el-table-column prop="print_status" align="center" label="打印状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.print_status == 2"
              >已打印</el-tag
            >
            <el-tag type="warning" v-else>未打印</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="printlist(scope.row)"
              >横版打印</el-button
            >
            <el-button type="text" @click="printlist(scope.row, 2)"
              >竖版打印</el-button
            >
            <el-button type="text" @click="exportHandle(scope.row)">
              导出
            </el-button>
            <el-button type="text" @click="handleEdit(scope.row)"
              >查看详情</el-button
            >
            <el-button
              v-if="role == 'tdc'"
              type="text"
              @click="printGoods(scope.row)"
              >分货单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增打印坑位自定义弹窗 start -->
    <print-set
      :dialogVisible="dialogVisible"
      @close="dialogVisible = false"
      @submitHandle="submitHandle"
      @setPrint="setPrint"
      :showSetButton="true"
      ref="printSetRef"
    />
    <!-- 新增打印坑位自定义弹窗 end -->
  </div>
</template>
<script>
import { BASE } from "../../api/index";
import printSet from "./modules/printSet.vue";
export default {
  name: "Receiving",
  components: { printSet },
  data() {
    return {
      dialogVisible: false,
      //列表新建页面初始参数
      loading: false,
      role: sessionStorage.getItem("manageIsdentity"),
      from: {
        delivery_take_order_batch_no: "",
        store_delivery_id: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        delivery_take_order_batch_no: "",
        store_delivery_id: "",
        page: 1,
        pageSize: 10,
      },
      value1: [],
      tableData: [],
      arrList: [],
      currentPage: 1,
      total: 0,
      multipleSelection: [],
      lsttables: [],
      detail: {}, // 打印对象
      printType: "", // 打印类型 竖版 横版
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from.page);
      this.from.pageSize = Number(this.from.pageSize);
    }
    this.hqlist();
    if (this.role == "tdc") {
      this.from.store_delivery_id = Number(this.from.store_delivery_id)
        ? Number(this.from.store_delivery_id)
        : "";
      this.from1.store_delivery_id = Number(this.from1.store_delivery_id)
        ? Number(this.from1.store_delivery_id)
        : "";
      this.getDeliveryList();
    }
  },
  methods: {
    // 设置默认分货方式
    setDefault() {
      this.dialogVisible = true;
      this.$refs.printSetRef.onInitData(1);
    },
    getDeliveryList() {
      this.$api.delivery.deliverylist().then((res) => {
        console.log(res, "获取数据");
        this.arrList = res.data;
      });
    },
    getKey(row) {
      return row.id;
    },
    toggleSelection(rows) {
      console.log(rows, "进入了吗");
      this.$nextTick(() => {
        if (rows) {
          rows.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    printGoods(el) {
      window.open(
        `${BASE.PRO4}/take/distribution/order/batch/export` +
          `?delivery_take_order_batch_id=${el.id}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
      this.hqlist();
    },
    printlist(el, type) {
      this.dialogVisible = true;
      this.detail = el;
      this.printType = type;
      this.$refs.printSetRef.onInitData(2, el);
      // window.open(
      //   `${
      //     this.role == "delivery" ? BASE.PRO3 : BASE.PRO4
      //   }/take/order/batch/export` +
      //     `?delivery_take_order_batch_id=${el.id}&token=${
      //       sessionStorage.getItem("token")
      //         ? sessionStorage.getItem("token")
      //         : ""
      //     }` +
      //     `${type ? "&print_type=2" : ""}`
      // );
      // this.hqlist();
      // this.$api.delivery.orderlisttiprint({ id: [el.id] }).then((res) => {
      //   console.log(res, "打印数据");
      //   this.hqlist();
      //   window.open(
      //     res.data +
      //       `?token:${
      //         sessionStorage.getItem("token")
      //           ? sessionStorage.getItem("token")
      //           : ""
      //       }`
      //   );
      // });
    },
    // 导出
    exportHandle(row) {
      window.open(
        `${
          this.role == "delivery" ? BASE.PRO3 : BASE.PRO4
        }/take/order/batch/exportCsv` +
          `?delivery_take_order_batch_id=${row.id}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
    },
    // 单个设置后 调用打印
    setPrint() {
      window.open(
        `${
          this.role == "delivery" ? BASE.PRO3 : BASE.PRO4
        }/take/order/batch/export` +
          `?delivery_take_order_batch_id=${this.detail.id}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }` +
          `${this.printType ? "&print_type=2" : ""}`
      );
    },
    print() {
      const map = new Map();
      const qc = this.lsttables.filter(
        (key) => !map.has(key.id) && map.set(key.id, 1)
      );
      // console.log(qc,'数据')
      this.lsttables = qc;
      let arr = JSON.parse(JSON.stringify(this.lsttables));
      if (arr.length > 0) {
        window.open(
          `${
            this.role == "delivery"
              ? BASE.PRO3 + "/order/print"
              : BASE.PRO4 + "/take/order/print"
          }` +
            `?ids=${arr.map((el) => el.id).join(",")}&token=${
              sessionStorage.getItem("token")
                ? sessionStorage.getItem("token")
                : ""
            }`
        );
        this.lsttables = [];
        this.hqlist();
        // this.$api.delivery
        //   .orderlisttiprint({ id: arr.map((el) => el.id) })
        //   .then((res) => {
        //     console.log(res, "打印数据");
        //     this.lsttables = [];
        //     this.hqlist();
        //     window.open(
        //       res.data +
        //         `?token:${
        //           sessionStorage.getItem("token")
        //             ? sessionStorage.getItem("token")
        //             : ""
        //         }`
        //     );
        //   });
      } else {
        this.$message({
          type: "error",
          message: "请选择一条数据打印",
        });
      }
    },
    handleSelectionChange(val) {
      console.log(val, "1111111111");
      this.lsttables = val;

      this.multipleSelection = val;
    },
    search() {
      // if (this.value1 != null) {
      //   if (this.value1.length > 0) {
      //     this.from.startDate = this.$time.time(this.value1[0]);
      //     this.from.endDate = this.$time.time(this.value1[1]);
      //     console.log(this.from.startDate, this.from.endDate, "时间");
      //   } else {
      //     this.from.startDate = "";
      //     this.from.endDate = "";
      //   }
      // } else {
      //   this.from.startDate = "";
      //   this.from.endDate = "";
      // }
      this.from.page = 1;
      this.currentPage = 1;
      this.from1 = this.from;
      console.log(window.location);
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },

    Refresh() {
      this.from = {
        delivery_take_order_batch_no: "",
        store_delivery_id: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        delivery_take_order_batch_no: "",
        store_delivery_id: "",
        page: 1,
        pageSize: 10,
      };
      this.lsttables = [];
      this.value1 = [];
      this.currentPage = 1;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleEdit(row) {
      this.$router.push({
        path: `/ReceivingCustomerDetail`,
        query: {
          id: row.id,
        },
      });
    },
    // 配置打印类型成功 刷新列表
    submitHandle() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from1 = this.from;
      console.log(window.location);
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      let form = this.tool.DeepCopy({}, this.from1);
      if (this.role == "delivery" && form.hasOwnProperty("store_delivery_id")) {
        delete form.store_delivery_id;
      }
      this.$api.delivery.batchlist(form).then((res) => {
        console.log(res, "获取数据");

        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
        this.toggleSelection(this.lsttables);
      });
    },
  },
};
</script>
<style lang="scss">
.Receiving {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs1 {
        width: 320px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}
</style>
